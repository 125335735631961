<mgl-map
      [style]="'https://demotiles.maplibre.org/style.json'"
      [zoom]="[2]"
      [center]="[-74.5, 40]"
    >
    <mgl-image
      id="pin"
      url="/assets/14.png"
      (imageLoaded)="imageLoaded = true">
  </mgl-image>
  @if(imageLoaded) {

    <mgl-geojson-source
        id="earthquakes"
        data="/assets/test.geojson"
        [cluster]="true"
        [clusterRadius]="40"
        [clusterProperties]="clusterProperties"
      ></mgl-geojson-source>

      <mgl-markers-for-clusters source="earthquakes">
        <ng-template mglClusterPoint let-feature>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="32" height="32" viewBox="0 0 1080 1080" xml:space="preserve">
            <desc>Created with Fabric.js 5.2.4</desc>
            <defs></defs>
            <g transform="matrix(1 0 0 1 540 540)" id="33cc69df-ef75-4839-8873-f1cae48cceaa"  >
              <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-540" y="-540" rx="0" ry="0" width="1080" height="1080" />
            </g>
              <g transform="matrix(1 0 0 1 540 540)" id="8cda2641-bfba-4cff-9ae5-db8d152a0718"  >
            </g>
            <g transform="matrix(15.07 0 0 15.07 540 540)" id="98f9730d-c134-4e5b-a092-8ecc0deed0b7"  >
              <circle style="stroke: rgb(0,0,0); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(156,79,195); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  cx="0" cy="0" r="35" />
            </g>
            <g transform="matrix(11.33 0 0 11.33 539.66 540.34)" id="6cd9873b-e322-4504-a2ff-5e118cd23024"  >
              <circle style="stroke: rgb(0,0,0); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  cx="0" cy="0" r="35" />
            </g>
          </svg>
        </ng-template>
      </mgl-markers-for-clusters>

    <!-- <mgl-layer
      id="points"
      type="symbol"
      [source]="source()"
      [layout]="{ 'icon-image': 'pin', 'icon-size': 0.4 }"
    ></mgl-layer> -->
  }
</mgl-map>