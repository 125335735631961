import { Routes } from '@angular/router';
import { NgxComponent } from 'src/app/ngx/ngx.component';
import { VanillaComponent } from 'src/app/vanilla/vanilla.component';

export const routes: Routes = [
  {
    path: 'ngx',
    component: NgxComponent,
  },
  {
    path: 'vanilla',
    component: VanillaComponent,
  },
  {
    path: '*',
    redirectTo: '/vanilla',
  }
];
