import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as maplibregl from 'maplibre-gl';
import * as pmtiles from 'pmtiles';

@Component({
  selector: 'app-vanilla',
  standalone: true,
  imports: [],
  templateUrl: './vanilla.component.html',
  styleUrl: './vanilla.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VanillaComponent {

  map: maplibregl.Map;

  constructor(private http: HttpClient) {}

  ngAfterViewInit() {
    const token = 'JZZDGPDXdB5O7KQTcV2h2GTi4248rdzuHpspoAGPSryAs1yvlAaZ118vaSrxzQ69';
    const map = this.map = new maplibregl.Map({
      container: 'map', // container id
      style: `https://api.jawg.io/styles/7c47e08c-62b8-4523-a265-32267d4f8e9b.json?access-token=${token}`, // style URL
      center: [0, 0], // starting position [lng, lat]
      zoom: 3, // starting zoom,
      minZoom: 2,
    });

    this.map.on('load', async () => {

      // const collection = await firstValueFrom(this.http.get<GeoJSON.FeatureCollection>('/assets/test.geojson', { responseType: 'json' }));
      const protocol = new pmtiles.Protocol();
      maplibregl.addProtocol('pmtiles', protocol.tile);


    const PMTILES_URL = '/assets/toto.pmtiles';

    const p = new pmtiles.PMTiles(PMTILES_URL);
    protocol.add(p);


    this.map.addSource('stations', {
      type: 'vector',
      url: `pmtiles://${PMTILES_URL}`,
    });


      // map.addSource('stations', {
      //   type: 'geojson',
      //   data: collection as any,
      //   cluster: true,
      //   clusterMaxZoom: 10, // Max zoom to cluster points on
      //   clusterRadius: 40,
      //   clusterMinPoints: 5,
      //   clusterProperties: {
      //     clusterTotal: ['+', ['get', 'value']]
      //   }
      // });

      const image = await map.loadImage('/assets/14.png');
      map.addImage('custom-marker', image.data);

      const markerOptions: maplibregl.SymbolLayerSpecification['layout'] = {
        'icon-image': 'custom-marker',
        'icon-size': 0.50,
        "icon-allow-overlap": true,
        'text-offset': [0, -0.5],
        'text-anchor': 'center',
        'text-size': 14,
        'text-font': ['Open Sans Bold']
      }

      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        'source-layer': 'test',
        source: 'stations',
        filter: ['!', ['has', 'point_count']],
        layout: {
          ...markerOptions,
          'text-field': ['get', 'value'],
        },
        'paint': {
          'text-color': 'white',
        }
      });

      map.addLayer({
        id: 'clusters',
        type: 'symbol',
        source: 'stations',
        'source-layer': 'test',
        filter: ['has', 'point_count'],
        layout: {
          ...markerOptions,
          'text-field': ['get', 'value'],
          // 'text-field': [
          //   'to-string',
          //   [
          //     'round',
          //     ['/', ['get', 'clusterTotal'], ["number", ["get", "point_count"]]],
          //   ]
          // ],
        },
        'paint': {
          'text-color': 'white',
        }
      });

    });
  }

}
