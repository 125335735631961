import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ClusterPointDirective, GeoJSONSourceComponent, ImageComponent, LayerComponent, MapComponent, MarkersForClustersComponent } from '@maplibre/ngx-maplibre-gl';
import { GeoJSONSourceSpecification } from 'maplibre-gl';

@Component({
  selector: 'app-ngx',
  standalone: true,
  imports: [MapComponent, LayerComponent, ImageComponent,
    GeoJSONSourceComponent,
    MarkersForClustersComponent,
    ClusterPointDirective,],
  templateUrl: './ngx.component.html',
  styleUrl: './ngx.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxComponent {

  imageLoaded = false

  clusterProperties: GeoJSONSourceSpecification['clusterProperties'] = {};

  constructor(private http: HttpClient) {}

  ngOnInit() {
    // this.http.get('/assets/test.geojson').subscribe((data: any) => {
    //   this.source.set({
    //     type: 'geojson',
    //     data: {
    //       type: 'FeatureCollection',
    //       features: data.features,
    //     }
    //   });
    // })
  }

  // source = signal(null);

}
